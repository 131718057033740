import logo from "./logo.svg";
import "./App.css";
import Main from "./components/mainpage/Main";
import "lenis/dist/lenis.css";
function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
