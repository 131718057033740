import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Footer.scss";
import icon from "../assets/iconwithtxt.png";
function Footer() {
	return (
		<Container fluid className="footer">
			<Row>
				<img src={icon} className="footerImg" />
			</Row>
			<Row className="footerCardRow">
				<Col md={4} className="footerCard">
					1-Click-Installation
				</Col>
				<Col md={4} className="footerCard">
					1-Click-Installation
				</Col>
				<Col md={4} className="footerCard">
					1-Click-Installation
				</Col>
			</Row>
			<Row className="footerClick">
				<Col md={3} className="footerClickElement">
					Product
				</Col>
				<Col md={3} className="footerClickElement">
					Developer
				</Col>
				<Col md={3} className="footerClickElement">
					About Us
				</Col>
				<Col md={3} className="footerClickElement">
					Partners
				</Col>
			</Row>
			<Row className="footerClick footerClick2">
				<Col md={4} className="footerClickElement">
					Terms & Conditions
				</Col>
				<Col md={4} className="footerClickElement">
					FAQ's
				</Col>
				<Col md={4} className="footerClickElement">
					Privacy Policy
				</Col>
			</Row>
		</Container>
	);
}

export default Footer;
