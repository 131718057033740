import React from "react";
import styled, { css } from "styled-components";
import "./CustNavbar.scss";
import icon from "../assets/iconwithtxt.png";
function CustNavbar({ style }) {
  return (
    <div className="customNav" id="customNav">
      <div className="elements" style={style}>
        <div className="left">
          <img src={icon} alt="icon"></img>
        </div>
        <div className="right">
          <ul>
            <li>Product</li>
            <li>Developer</li>
            <li>About Us</li>
            <li>Partners</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CustNavbar;
